<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <ApeMenu :menus="menus"/>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'LogisticsIndex',
  components: {
    PageHeaderLayout
  },
  data() {
    return {
      menus: [
        {
          icon: 'liebiao',
          display_name: '快递公司管理列表',
          url: '/logistics/company',
          permission_name: 'kol-analysis-list'
        },
        {
          icon: 'jiekuan',
          display_name: '物流信息管理',
          url: '/logistics/list',
          permission_name: 'kol-analysis-list'
        },
      ]
    }
  }
}
</script>

<style scoped></style>
